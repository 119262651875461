import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Button, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useFirebase } from 'gatsby-plugin-firebase';
import { Experimental, MultiLanguage, OpenGraph, PreConnect, PreLoad, SEO, TwitterCard } from '@unicat/Seo';
import Language from '@unicat/i18n/LanguageContext';
import t from '@unicat/i18n/Translate';
import { config } from '@config';

export const query = graphql`
  query SiteDemoPageTitleQuery {
    site {
      meta: siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const fieldIds = {
  orderId: 'orderId',
  userId: 'userId',
  status: 'status',
  phone: 'phone',
  message: 'message',
  reply: 'reply',
};

const Demo = (context) => {
  const { data: queryData, pageContext, location } = context;
  const { control, handleSubmit, errors, reset } = useForm({ reValidateMode: 'onChange' });

  const [data, setData] = React.useState({});
  const [success, setSuccess] = useState(false);

  //firebase hook for sending data
  useFirebase(
    (firebase) => {
      firebase
        .database()
        .ref('/messages')
        .push(data)
        .then((res) => {
          setSuccess(true);
          return res;
        })
        .catch(() => {
          setSuccess(false);
          // console.warn(e);
        });
    },
    [data],
  );

  /* generate random id, only for testing */
  const randomID = () => Math.random().toString(36).slice(2);

  // get form values
  const onSubmit = (values) => {
    setData({ ...values });
  };

  // reset controlled form inputs
  useEffect(() => {
    if (success) {
      reset({ ...data });
      setSuccess(false);
    }
  }, [success, data, reset]);

  const title = pageContext.pageName;
  const { description } = queryData.site.meta;
  const language = useContext(Language);
  const { lang } = language ?? { lang: config.DefaultLanguage };

  return (
    <>
      <>
        <SEO title={title} description={description} lang={lang} />
        <MultiLanguage lang={lang} location={location} />
        <TwitterCard title={title} />
        <OpenGraph title={title} />
        <PreLoad />
        <PreConnect />
        <Experimental />
      </>

      <div className={'container'}>
        <form
          style={{ maxWidth: 600, margin: '80px auto 0' }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}>
          {/* test order id */}
          <Controller
            as={TextField}
            id={fieldIds.orderId}
            name={fieldIds.orderId}
            label={t('Order ID')}
            defaultValue={randomID()}
            control={control}
            fullWidth
            helperText={t('Unique order identifier.')}
            required
            rules={{
              required: {
                message: 'This field is required',
                value: true,
              },
            }}
          />
          {errors[fieldIds.orderId]?.type && <span className={'text-danger'}>{errors[fieldIds.orderId]?.message}</span>}

          {/* test user id */}
          <Controller
            required
            as={TextField}
            id={fieldIds.userId}
            name={fieldIds.userId}
            label={t('User ID')}
            defaultValue={randomID()}
            control={control}
            fullWidth
            helperText={t('Unique user identifier.')}
            rules={{
              required: {
                message: 'This field is required',
                value: true,
              },
            }}
          />
          {errors[fieldIds.userId]?.type && <span className={'text-danger'}>{errors[fieldIds.userId]?.message}</span>}

          {/* order status */}
          <Controller
            as={TextField}
            id={fieldIds.status}
            name={fieldIds.status}
            label={t('Message status')}
            defaultValue={'new Order'}
            fullWidth
            control={control}
            helperText={t('Message status')}
          />
          {/* phone number */}
          <Controller
            as={TextField}
            id={fieldIds.phone}
            name={fieldIds.phone}
            required
            defaultValue={''}
            label={t('Phone number')}
            control={control}
            fullWidth
            helperText={t('Phone number of the user')}
            rules={{
              pattern: {
                value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/i,
                message: 'invalid phone',
              },
              required: 'This field is required',
            }}
          />
          {errors[fieldIds.phone]?.type && <span className={'text-danger'}>{errors[fieldIds.phone]?.message}</span>}

          {/* order message */}
          <Controller
            as={TextField}
            id={fieldIds.message}
            name={fieldIds.message}
            label={t('Send message')}
            control={control}
            fullWidth
            helperText={t('Text of send message')}
            defaultValue={''}
          />
          {/* reply for order */}
          <Controller
            as={TextField}
            id={fieldIds.reply}
            name={fieldIds.reply}
            control={control}
            multiline
            rows={4}
            defaultValue={''}
            label={t('Delivery function reply')}
            fullWidth
            helperText={t('Delivery function reply')}
          />

          <Button type={'submit'} disabled={!!Object.keys(errors).length}>
            Order
          </Button>
        </form>
      </div>
    </>
  );
};

export default Demo;
